import SOC from '@/app/db/soc';
import Icon from '@/app/ui/icons/Icon';

export default function Footer() {
  return (
    <div className="flex flex-col items-center bg-deep-midnight py-10">
      <p className="text-[24px] uppercase text-baby-blue">
        follow <span className="text-cobalt-blue">and</span> stay tuned
      </p>
      <div className="flex items-center gap-[42px] pb-2 pt-[18px] text-baby-blue">
        <a
          aria-label="Telegram"
          className="hover:text-ice-blue"
          target="_blank"
          href={SOC.telegram}
        >
          <Icon name="telegram" width={46} height={44} />
        </a>
        <a
          aria-label="Discord"
          className="hover:text-ice-blue"
          target="_blank"
          href={SOC.discord}
        >
          <Icon name="discord" width={54} height={40} />
        </a>
        <a
          aria-label="X"
          className="hover:text-ice-blue"
          target="_blank"
          href={SOC.x}
        >
          <Icon name="twitter" width={45} height={44} />
        </a>
      </div>
      <div className="flex rotate-90">
        <Icon name="divider" width={2} height={27} />
      </div>
      <div>
        <a
          href="/pdf/privacy-policy.pdf"
          target="_blank"
          className="text-baby-blue hover:text-ice-blue"
        >
          Privacy Policy
        </a>
      </div>
      <div className="mb-3 flex rotate-90">
        <Icon name="divider" width={2} height={27} />
      </div>
      <p className="text-cerulean-blue">Copyright © 2024 Gamster</p>
    </div>
  );
}

