'use client';

import Image from 'next/image';
import { supercell } from '@/app/ui/font';
import Icon from '@/app/ui/icons/Icon';
import SectionsWrapper from '@/app/ui/SectionsWrapper';
import { useEffect, useRef, useState } from 'react';
import { useNavigation } from '@/app/contexts/Navigation';
import SOC from '@/app/db/soc';
import { toast } from 'react-toastify';
import Axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
// import Timer from '@/app/ui/wallet/Timer/Timer';
// import marketUrl from '@/app/db/market';
import hamster from './images/hamster.webp';

export default function HeroSection() {
  const recaptchaInstance = useRef<ReCAPTCHA | null>(null);
  const ref = useRef<HTMLDivElement | null>(null);
  const { setRefs } = useNavigation();
  const [email, setEmail] = useState('');
  const [submited, setSubmited] = useState(false);

  useEffect(() => {
    if (ref.current) {
      setRefs?.((prev) => ({ ...prev, hero: ref.current }));
    }
  }, [ref, setRefs]);

  const onSubmitEmail = async () => {
    try {
      if (recaptchaInstance && recaptchaInstance.current) {
        const token = await recaptchaInstance.current.executeAsync();
        if (token) {
          const request = await Axios.post('/api/subscribe', { email, token });
          if (request.data.status) {
            setSubmited(true);
          } else {
            toast('Something went wrong.', {
              type: 'error',
            });
          }
        } else {
          toast('You must complete reCAPTCHA.', {
            type: 'error',
          });
        }
        setTimeout(() => recaptchaInstance?.current?.reset(), 500);
      }
    } catch (error: any) {
      setSubmited(false);
      if (error && error.response) {
        toast(error.response.data.issues[0].message, {
          type: 'error',
        });
        setTimeout(() => recaptchaInstance?.current?.reset(), 500);
      }
    }
  };

  return (
    <SectionsWrapper className="min-h-screen pt-[103px] lg:h-screen lg:pt-[152px]">
      <div
        ref={ref}
        className="absolute left-0 top-0 -z-[1] h-[101%] w-full overflow-hidden"
      >
        <div className="absolute z-20 h-full w-full bg-hero-video-gradient" />
        <video
          autoPlay
          muted
          loop
          playsInline
          className="absolute left-2/4 top-2/4 size-auto min-h-full min-w-full -translate-x-2/4 -translate-y-2/4 object-cover"
          poster="/assets/backgrounds/hero_background.webp"
        >
          <source src="/videos/hero-background.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="container z-10 mt-12 h-[95%] lg:mt-0">
        <p className="hero-text-shadow flex w-full justify-center gap-2 text-[24px] text-white lg:text-3xl">
          <span className="text-sky-blue">Haunted</span>
          <span className="hidden lg:block">CO-OP Play-to-Earn</span>
          <span className="text-sky-blue">Adventure</span>
        </p>
        <div className="relative flex h-full flex-col">
          <h1
            className={`${supercell.className} hero-section-brand-text text-center text-[62px] uppercase text-ice-blue lg:mt-[41px] lg:text-[10rem] lg:leading-[1] 2xl:text-[12.7rem]`}
          >
            gamster
          </h1>
          <div className="relative left-0 right-0 mx-auto -mt-8 h-[306px] w-full max-w-[292px] lg:absolute lg:bottom-[80px] lg:mt-0 lg:h-[619px] lg:max-h-[50vh] lg:w-full lg:max-w-[569px]">
            <div className="lg:blur-165 mx-auto size-[290px] rounded-full bg-[#2F89FF] blur-[65px] lg:h-full lg:w-full lg:bg-translucent-azure" />
            <Image
              src={hamster}
              fill
              alt="hamster"
              className="object-contain object-bottom"
            />
            {/* <div className="absolute -top-6 right-[-30rem] z-30 hidden h-[302px] w-[419px] rotate-[2.85deg] px-[28px] py-[50px] lg:block">
              <div className="absolute inset-0">
                <span className="absolute left-1 top-1">
                  <Icon
                    name="nail"
                    width={69}
                    height={70}
                    viewBox="0 0 39 40"
                  />
                </span>
                <span className="absolute right-0 top-0">
                  <Icon
                    name="nail"
                    width={69}
                    height={70}
                    viewBox="0 0 39 40"
                  />
                </span>
                <Icon
                  width={417}
                  height={448}
                  viewBox="0 0 417 448"
                  name="heroSaleBoard"
                />
              </div>
              <div className="relative -top-3 flex flex-col items-center justify-center">
                <p className="text-center text-[64px] uppercase leading-[110%] text-baby-blue">
                  get $GOin
                </p>
                <div className="mt-[10px] text-baby-blue">
                  <p className="mb-[2px] text-center text-[25px] uppercase">
                    <span className="text-pale-blue">With</span> public sale
                  </p>
                  <p className="text-center text-[25px] uppercase text-vivid-orange">
                    special starting price
                  </p>
                  <div className="mt-6 h-[80px] w-full ">
                    <Timer design="seed" />
                  </div>

                  <div className="mt-6 flex justify-between gap-1 text-[25px]">
                    <a
                      target="_blank"
                      href={marketUrl}
                      className="mx-auto flex h-[53px] w-[316px] items-center justify-center gap-[27px] rounded-[73px] bg-vivid-orange px-6 text-pale-mist"
                    >
                      Buy on Fjord Foundry
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          {/* <div className="relative z-30 mx-auto -mt-10 block h-[306px] w-[284px] p-[18px] lg:hidden">
            <div className="absolute inset-0">
              <span className="absolute left-1 top-0">
                <Icon name="nail" width={39} height={40} viewBox="0 0 39 40" />
              </span>
              <span className="absolute right-0 top-0">
                <Icon name="nail" width={39} height={40} viewBox="0 0 39 40" />
              </span>
              <Icon
                width={284}
                height={306}
                viewBox="0 0 421 452"
                name="heroSaleBoard"
              />
            </div>
            <div className="relative flex flex-col items-center justify-center">
              <p className="mt-1 text-center text-[42px] uppercase leading-[110%] text-baby-blue">
                get $GOin
              </p>
              <div className="mt-[8px] text-baby-blue">
                <p className="mb-[2px] text-center text-[16px] uppercase">
                  <span className="text-pale-blue">With</span> public sale
                </p>
                <p className="text-center text-[16px] uppercase text-vivid-orange">
                  special starting price
                </p>
                <div className="mt-3 h-[50px] w-full ">
                  <Timer design="seed" />
                </div>

                <div className="mt-8 flex justify-between gap-1 text-[16px]">
                  <a
                    href={marketUrl}
                    target="_blank"
                    className="flex h-[40px] w-full items-center justify-center gap-[17px] rounded-[73px] bg-vivid-orange px-6 text-pale-mist"
                  >
                    Buy on Fjord Foundry
                  </a>
                </div>
              </div>
            </div>
          </div> */}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmitEmail();
            }}
            // mt-1
            className="relative z-30 mb-5 mt-1 flex flex-col items-center lg:hidden"
          >
            <ReCAPTCHA
              ref={recaptchaInstance}
              size="invisible"
              sitekey={process.env.NEXT_PUBLIC_SITE_KEY ?? ''}
            />
            <div className="mb-[9px] flex gap-[14px] text-sky-blue">
              <span className="flex h-[29px] items-center justify-center rounded-[48px] bg-[rgba(9,10,1,0.52)] px-[24px]">
                Escape-to-Earn
              </span>
              <span className="flex h-[29px] items-center justify-center rounded-[48px] bg-[rgba(9,10,1,0.52)] px-[24px]">
                Co-Op
              </span>
            </div>
            <input
              type="text"
              value={email}
              name="EMAIL"
              onChange={({ target }) => setEmail(target.value)}
              placeholder="Enter your Email here"
              className="quiroh-font mb-[17px] h-[60px] w-full max-w-[362px] rounded-[13px] border-2 border-[rgba(135,217,241,0.3)] bg-[#030A2E] text-center text-pale-mist placeholder:text-[rgba(170,212,249,0.6)] focus-visible:outline-none"
            />
            {submited ? (
              <div className="flex h-[55px] w-[220px] items-center justify-center rounded-[71px] border-2 border-[rgba(232,237,239,0.76)] bg-[#030A2E] uppercase text-sky-blue">
                CONFIRMED
                <Icon
                  name="success"
                  width={25}
                  height={25}
                  viewBox="0 0 92 92"
                />
              </div>
            ) : (
              <button
                type="submit"
                className="flex h-[55px] w-[220px] items-center justify-center rounded-[71px] border-2 border-[rgba(232,237,239,0.76)] bg-[#030A2E] uppercase text-sky-blue"
              >
                Game Waitlist
              </button>
            )}
          </form>
          <p className="relative z-30 mb-3 block text-center text-[20px] uppercase text-sky-blue lg:hidden">
            Stay tuned
          </p>
          <div className="relative z-30 flex w-[245px] items-center justify-center gap-[25px] self-center rounded-[20px] py-[14px] text-[#6A8AAA] lg:hidden">
            <a
              aria-label="Telegram"
              className="hover:text-ice-blue"
              href={SOC.telegram}
              target="_blank"
            >
              <Icon
                name="telegram"
                width={36}
                height={35}
                viewBox="0 0 46 44"
              />
            </a>
            <a
              aria-label="Discord"
              className="hover:text-ice-blue"
              href={SOC.discord}
              target="_blank"
            >
              <Icon name="discord" width={44} height={33} viewBox="0 0 54 40" />
            </a>
            <a
              aria-label="X"
              className="hover:text-ice-blue"
              href={SOC.x}
              target="_blank"
            >
              <Icon name="twitter" width={36} height={35} viewBox="0 0 45 44" />
            </a>
          </div>
          <div className="bottom-10 z-20 mt-[39px] hidden w-full flex-col items-center justify-center lg:absolute lg:mt-0 lg:flex lg:flex-row lg:justify-between">
            <div className="relative flex-col text-[#8a96ac]">
              <p className="mb-1.5">Launching on:</p>
              <div>
                <Icon name="base" width={78} height={20} />
              </div>
            </div>
            {/* <SubscribeForm /> */}
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmitEmail();
              }}
              className="ml-[8rem] flex w-full max-w-[580px] items-center justify-center rounded-[38px] bg-[rgba(0,13,31,0.4)] px-[25px] lg:h-[78px]"
            >
              <ReCAPTCHA
                ref={recaptchaInstance}
                size="invisible"
                sitekey={process.env.NEXT_PUBLIC_SITE_KEY ?? ''}
              />
              <input
                type="text"
                name="EMAIL"
                placeholder="Enter your email here"
                value={email}
                onChange={({ target }) => setEmail(target.value)}
                className="quiroh-font flex h-[44px] flex-1 items-center rounded-bl-[38px] rounded-tl-[38px] bg-[rgba(23,42,92,0.42)] px-[23px] text-pale-mist placeholder:text-[rgba(170,212,249,0.6)] focus-visible:outline-none"
              />
              {submited ? (
                <div className="flex h-[44px] w-[172px] items-center justify-center rounded-br-[38px] rounded-tr-[38px] bg-baby-blue uppercase text-[#060A28]">
                  CONFIRMED
                  <Icon
                    name="success"
                    width={25}
                    height={25}
                    viewBox="0 0 92 92"
                  />
                </div>
              ) : (
                <button
                  type="submit"
                  className="flex h-[44px] w-[172px] items-center justify-center rounded-br-[38px] rounded-tr-[38px] bg-baby-blue uppercase text-[#060A28]"
                >
                  Game Waitlist
                </button>
              )}
            </form>

            <div className="block rotate-90 lg:hidden">
              <Icon name="divider" width={2} height={42} />
            </div>
            <div className="flex items-center gap-[25px] text-steel-blue lg:gap-[42px]">
              <a
                href={SOC.telegram}
                target="_blank"
                aria-label="Telegram"
                type="button"
                className="hover:text-ice-blue"
              >
                <Icon name="telegram" width={46} height={44} />
              </a>
              <a
                href={SOC.discord}
                target="_blank"
                aria-label="Discord"
                type="button"
                className="hover:text-ice-blue"
              >
                <Icon name="discord" width={54} height={40} />
              </a>
              <a
                href={SOC.x}
                target="_blank"
                aria-label="X"
                type="button"
                className="hover:text-ice-blue"
              >
                <Icon name="twitter" width={45} height={44} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 z-10 h-[233px] w-full bg-hero-section-background-shadow" />
    </SectionsWrapper>
  );
}
