const data = [
  {
    id: 'seed',
    label: 'Seed',
    value: 5,
  },
  {
    id: 'public',
    label: 'Public',
    value: 10,
  },
  {
    id: 'treasury',
    label: 'Treasury',
    value: 26,
  },
  {
    id: 'team_and_advisors',
    label: 'Team & Advisors',
    value: 8,
  },
  {
    id: 'development',
    label: 'Development',
    value: 10,
  },
  {
    id: 'marketing',
    label: 'Marketing',
    value: 15,
  },
  {
    id: 'cex_liquidity',
    label: 'CEX Liquidity',
    value: 6,
  },
  {
    id: 'dex_liquidity',
    label: 'DEX Liquidity',
    value: 8,
  },
  {
    id: 'strategic_partnerships',
    label: 'Strategic partnerships',
    value: 12,
  },
];

export default data;

