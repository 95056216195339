const data = [
  {
    year: 2024,
    quarter: 'Q4',
    className: 'rotate-[-2.033deg]',
    list: [
      'Community building',
      'Smart contract Creation',
      'Kickstarting Development',
    ],
  },
  {
    year: 2025,
    quarter: 'Q1',
    className: 'rotate-[-0.611deg]',
    list: ['Strategic partnerships', 'Player Clans', 'Community events'],
  },
  {
    year: 2025,
    quarter: 'Q2',
    className: 'rotate-[0.836deg]',
    list: ['MVP product launch', 'Referral program', 'Telegram game bot'],
  },
  {
    year: 2025,
    quarter: 'Q3',
    className: 'rotate-[1.36deg]',
    list: [
      'Beta Version Release',
      'Token monetization',
      'Co-op Implementation',
    ],
  },
];

export default data;

