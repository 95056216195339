import Image from 'next/image';
import Icon from '@/app/ui/icons/Icon';
import chartBG from './images/chart_bg.webp';

export default function Chart() {
  return (
    <div className="relative hidden h-[785px] w-[668px] lg:block">
      <Image src={chartBG} fill alt="Chart background" />
      <div className="absolute left-[11.7rem] top-[7rem] size-full -translate-x-2/4">
        <Icon name="chart" width={810} height={620} viewBox="0 0 876 743" />
      </div>
    </div>
  );
}

